<template>
  <div class="gift-btn-wrapper">
    <AuthVisitorLoginModal
      :dialog-visible="showLogin"
      @closeModal="isLoginDialogClosed"
    />

    <giftConfirmationDialog
      :show="confirmDialog"
      @confirm="addGift"
      @dialogClosed="confirmDialog = false"
      :subscription-type="form.type"
      :is-new-customer="giftedToNewCustomer"
    />

    <v-dialog
      v-if="isLogin"
      class="gift-dialog"
      persistent
      scrollable
      v-model="dialog"
      width="620"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-if="isIconBtn"
          class="icon-gift-btn"
          elevation="0"
          :disabled="isBtnDisabled"
          v-bind="props"
          icon
        >
          <img
            v-bind="props"
            src="/images/settings/gift-icon.svg"
            alt="gift-icon"
          />
        </v-btn>
        <v-btn
          v-else
          class="gift-btn text-primary font-weight-bold"
          elevation="0"
          :disabled="isBtnDisabled"
          v-bind="props"
          rounded
        >
        إهداء المادة
          <img
            v-bind="props"
            src="~/assets/images/shared/gift.svg"
            alt="gift-icon"
            class="mr-2"
          />
        </v-btn>
      </template>

      <div class="card-wrapper px-0 py-0 dialog-content">
        <v-card elevation="0" class="gift-card">
          <v-btn
            elevation="0"
            size="small"
            icon
            color="transparent"
            @click="dialog = false"
            class="close-dialog-icon"
          >
            <v-icon class="text-black">mdi-close</v-icon>
          </v-btn>
          <div class="gift-sections-wrapper">
            <div>
              <div class="card-header">
                <img src="/images/settings/gift-header-img.svg" alt="" />
                <span>الإهداء</span>
                <div v-if="showProgress" class="progress-container">
                  <client-only>
                    <step-progress
                      :steps="mySteps"
                      :current-step="currentStep"
                      icon-class="fa fa-check"
                      :line-thickness="3"
                      :active-thickness="3"
                      :passive-thickness="3"
                    ></step-progress>
                  </client-only>
                </div>
              </div>

              <transition name="slide-fade2">
                <div
                  v-if="showFirstForm && giftableData && giftableData.cases"
                  class="subscription__type"
                >
                  <div class="sub-container2">
                    <v-tooltip
                      :class="{ 'my-0 mx-auto': xsOnly }"
                      v-if="!giftableData.cases.can_gift_new_sub"
                      max-width="250"
                      color="#8e6ba1"
                      top
                    >
                      <template v-slot:activator="{ props }">
                        <v-btn width="39" v-bind="props" icon>
                          <img
                            src="/images/course/help-circle.svg"
                            alt="help-circle"
                          />
                        </v-btn>
                      </template>
                      <span>
                        {{ giftableData.messages.can_gift_new_sub_message }}
                      </span>
                    </v-tooltip>
                    <div class="d-flex align-center">
                      <v-btn
                        :disabled="!giftableData.cases.can_gift_new_sub"
                        small
                        :class="{ 'active-btn': form.type === 'new' }"
                        class="type-btn2"
                        rounded
                        outlined
                        @click="form.type = 'new'"
                      >
                        إهداء إشتراك جديد
                      </v-btn>
                    </div>
                    <div class="d-flex align-center">
                      <v-btn
                        :disabled="!giftableData.cases.can_gift_current_sub"
                        small
                        :class="{ 'active-btn': form.type === 'convert' }"
                        class="type-btn2"
                        rounded
                        outlined
                        @click="form.type = 'convert'"
                      >
                        إهداء إشتراك حالي
                      </v-btn>
                    </div>
                    <v-tooltip
                      :class="{ 'my-0 mx-auto': xsOnly }"
                      v-if="!giftableData.cases.can_gift_current_sub"
                      max-width="250"
                      color="#8e6ba1"
                      top
                    >
                      <template v-slot:activator="{ props }">
                        <v-btn width="39" v-bind="props" icon>
                          <img
                            src="/images/course/help-circle.svg"
                            alt="help-circle"
                          />
                        </v-btn>
                      </template>
                      <span>
                        {{ giftableData.messages.can_gift_current_sub_message }}
                      </span>
                    </v-tooltip>
                  </div>
                  <p v-if="form.type === 'new'" class="hint-text">
                    بإختيارك هذا الخيار ، انت تقوم بإهداء اشتراك جديد لا يؤثر
                    هذا الخيار على اشتراكاتك الحالية
                  </p>

                  <p v-else class="hint-text">
                    بعد إتمام هذه الخطوة ، سيتم نقل اشتراكك الحالي من حسابك إلى
                    حساب الطرف الآخر
                  </p>
                </div>
                <div v-else-if="currentStep === 0" class="loading-text">
                  <p class="text-center">جارٍ التحميل...</p>
                </div>
              </transition>

              <transition name="slide-fade">
                <div v-if="showSecondForm" class="gift__type">
                  <div class="sub-container">
                    <div class="d-flex justify-center">
                      <v-btn
                        small
                        :class="{ 'active-btn': !form.is_gifted_to_unknown }"
                        class="type-btn"
                        rounded
                        outlined
                        @click="form.is_gifted_to_unknown = false"
                      >
                        إهداء لشخص محدد
                      </v-btn>
                    </div>
                    <div class="d-flex justify-center">
                      <v-btn
                        small
                        :class="{ 'active-btn': form.is_gifted_to_unknown }"
                        class="type-btn"
                        rounded
                        outlined
                        @click="form.is_gifted_to_unknown = true"
                      >
                        إهداء لشخص غير محدد
                      </v-btn>
                    </div>
                  </div>
                  <p v-if="form.is_gifted_to_unknown" class="not-selected-text">
                    ستقوم بإهداء الاشتراك الى شخص مستحق
                  </p>
                </div>
              </transition>

              <transition name="slide-fade2">
                <v-card-text v-if="showThirdForm" class="px-8">
                  <v-form ref="formRef" class="gift-form" lazy-validation>
                    <div class="form-wrapper">
                      <v-row v-if="!form.is_gifted_to_unknown">
                        <v-col cols="12">
                          <v-text-field
                            v-model.trim="form.gifted_to_email"
                            :rules="emailRules"
                            class="email-field"
                            solo
                            placeholder="البريد الالكتروني للطرف الآخر"
                            type="text"
                            validate-on-blur
                          />
                        </v-col>
                        <v-col class="radio-col" cols="12">
                          <v-radio-group v-model="form.is_messaged" inline>
                            <v-radio
                              color="#87599d"
                              label="أرسلها باسمي"
                              :value="true"
                            />
                            <v-radio
                              color="#87599d"
                              label="أرسلها كشخص مجهول"
                              :value="false"
                            />
                          </v-radio-group>
                        </v-col>
                        <v-col
                          v-if="form.is_messaged"
                          cols="6"
                          class="neg-margin"
                        >
                          <v-text-field
                            v-model="form.sender"
                            class="email-field"
                            solo
                            placeholder="من"
                            type="text"
                            :rules="requiredRules"
                            validate-on-blur
                          />
                        </v-col>
                        <v-col
                          v-if="form.is_messaged"
                          cols="6"
                          class="neg-margin"
                        >
                          <v-text-field
                            v-model="form.recipient"
                            class="email-field"
                            solo
                            placeholder="إلى"
                            type="text"
                            :rules="requiredRules"
                            validate-on-blur
                          />
                        </v-col>
                        <v-col
                          v-if="form.is_messaged"
                          cols="12"
                          class="neg-margin"
                        >
                          <v-textarea
                            v-model="form.message"
                            variant="outlined"
                            placeholder="نص الرسالة ... "
                            :rules="requiredRules"
                            validate-on-blur
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </v-card-text>
              </transition>
            </div>
            <div>
              <v-row class="btns-row">
                <v-col cols="12">
                  <div class="btns-container">
                    <v-btn
                      v-if="currentStep !== mySteps.length - 1"
                      :loading="btnLoading"
                      :disabled="
                        giftableData &&
                        !giftableData.cases.can_gift_new_sub &&
                        !giftableData.cases.can_gift_current_sub
                      "
                      @click="goToNext"
                      variant="text"
                      class="check-email-btn"
                      color="#fff"
                    >
                      التالي
                    </v-btn>
                    <v-btn
                      v-else
                      :loading="giftStore.loading"
                      @click="showConfirmDialog"
                      variant="text"
                      class="check-email-btn"
                      color="#fff"
                    >
                      إهداء
                    </v-btn>
                    <v-btn
                      v-if="currentStep > 0"
                      @click="goToPrevious"
                      variant="text"
                      outlined
                      class="previous-btn"
                    >
                      رجوع
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </div>
    </v-dialog>
    <v-btn
      v-else-if="!isLogin && isIconBtn"
      class="icon-gift-btn"
      :disabled="isBtnDisabled"
      @click="showLogin = true"
      icon
    >
      <img src="/images/settings/gift-icon.svg" alt="gift-icon" />
    </v-btn>
    <v-btn
          v-else-if="!isLogin && !isIconBtn"
          class="gift-btn text-primary font-weight-bold"
          elevation="0"
          :disabled="isBtnDisabled"
          rounded
          @click="showLogin = true"
        >
        إهداء المادة
          <img
            src="~/assets/images/shared/gift.svg"
            alt="gift-icon"
            class="mr-2"
          />
        </v-btn>
  </div>
</template>

<script setup>
import giftConfirmationDialog from "~/components/cards/giftConfirmationDialog.vue";
import LoginModal from "~/components/auth/LoginModal.vue";
import { useGiftStore } from "~/stores/GiftStore";
import { useAuthStore } from "~/stores/AuthStore";
import { useCartStore } from "~/stores/CartStore";
import { useDisplay } from "vuetify";
import { useHomeStore } from "~/stores/HomeStore";
const homeStore = useHomeStore();
import { ref } from "vue";

const { xsOnly } = useDisplay();
const cartStore = useCartStore();
const authStore = useAuthStore();
const giftStore = useGiftStore();

const emit = defineEmits(["onGift"]);

const props = defineProps({
  isBtnDisabled: {
    type: Boolean,
    default: false,
  },
  isIconBtn: {
    type: Boolean,
    default: true,
  },
  productId: {
    type: String,
    default: "",
  },
  productData: {
    type: Object,
    default: null,
  },
  giftData: {
    type: Object,
    default: null,
  },
  productType: {
    type: String,
    default: "",
  },
  checkInsideCart: {
    type: Boolean,
    default: false,
  },
  checkIsRelatedCourse: {
    type: Boolean,
    default: false,
  },
  giftEmail: {
    type: String,
    default: "",
  },
});

const route = useRoute();
const router = useRouter();

const showProgress = ref(false);
const mySteps = ref(["نوع الإهداء", "اختيار الشخص", "تفاصيل الإهداء"]);
const currentStep = ref(0);
const giftedToNewCustomer = ref(false);
const isClicked = ref(false);
const confirmDialog = ref(false);
const showFirstForm = ref(true);
const showLogin = ref(false);
const showSecondForm = ref(false);
const showThirdForm = ref(false);
const dialog = ref(false);
const btnLoading = ref(false);
const previousTimer = ref(null);
const nextTimer = ref(null);

const formRef = ref({});

const form = ref({
  is_gifted_to_unknown: false,
  gifted_to_email: "",
  type: "new",
  is_messaged: false,
  sender: "",
  recipient: "",
  message: "",
});

const requiredRules = [(v) => !!v || "هذا الحقل مطلوب"];
const emailRules = [
  (v) => !!v || "البريد الإلكتروني مطلوب",
  (v) =>
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v,
    ) || "البريد الإلكتروني غير صحيح",
];

const giftableData = computed(() => giftStore.giftableData);
const isLogin = computed(() => authStore.auth.isLoggedIn);

watch(dialog, async (val) => {
  if (!val) {
    resetForm();
  } else {
    showFirstForm.value = false;
    await checkGiftable();
    if (props.checkInsideCart) {
      if (props.giftData) {
        const { id, ...takeValues } = props.giftData;
        Object.assign(form.value, takeValues);
      }
    }
    if (giftableData.value && giftableData.value.cases.can_gift_new_sub) {
      form.value.type = "new";
    } else if (
      giftableData.value &&
      giftableData.value.cases.can_gift_current_sub
    ) {
      form.value.type = "convert";
    } else {
      form.value.type = "";
    }
    showFirstForm.value = true;
  }
});

watch(
  () => form.value.is_gifted_to_unknown,
  (val) => {
    if (val) {
      mySteps.value = ["اختيار الشخص", "نوع الإهداء"];
    } else {
      mySteps.value = ["اختيار الشخص", "نوع الإهداء", "تفاصيل الإهداء"];
    }
  },
);

const addGift = async () => {
  confirmDialog.value = false;

  const valid =
    formRef.value && formRef.value.errors
      ? (await formRef.value.validate()).valid
      : true;

  if (valid) {
    const formData = new FormData();
    btnLoading.value = true;

    if (props.checkInsideCart) {
      formData.append("_method", "patch");
      if (form.value.is_gifted_to_unknown) {
        appendFormData(formData, [
          "sender",
          "recipient",
          "message",
          "is_messaged",
          "gifted_to_email",
        ]);
      } else {
        appendFormData(formData, ["sender", "recipient", "message"]);
      }
      await updateGift(formData);
    } else {
      if (route.name === "cart" && !props.checkIsRelatedCourse) {
        formData.append("gifted_from_cart", true);
      }
      formData.append("product_id", props.productData.id);
      formData.append("product_type", props.productType);
      if (form.value.is_gifted_to_unknown) {
        appendFormData(formData, [
          "sender",
          "recipient",
          "message",
          "gifted_to_email",
        ]);
      } else {
        appendFormData(formData, ["sender", "recipient", "message"]);
      }
      await addNewGift(formData);
    }
  }
};

const setSubscriptionType = (type) => {
  form.value.subscription_type = type;
};

const checkGiftable = async (isConfirmed) => {
  const formData = new FormData();
  formData.append("product_id", props.productData.id);
  formData.append("product_type", props.productType);
  if (form.value.gifted_to_email) {
    formData.append("gifted_to_email", form.value.gifted_to_email);
  }
  if (route.name === "cart") {
    formData.append("gifted_from_cart", route.name === "cart");
  }

  try {
    await giftStore.checkGiftable(formData);
    if (currentStep.value === mySteps.value.length - 1) {
      confirmDialog.value = true;
    }
    if (giftableData.value.cases.can_gift_to_customer) {
      giftedToNewCustomer.value = true;
      confirmDialog.value = true;
    }
  } catch (err) {
    if (isConfirmed) {
      emitGiftError(err);
    }
  }
};

const goToNext = () => {
  clearTimeout(nextTimer.value);
  nextTimer.value = setTimeout(() => {
    if (currentStep.value === 0) {
      showFirstForm.value = false;
      setTimeout(() => {
        showSecondForm.value = true;
      }, 400);
    } else if (currentStep.value === 1) {
      showSecondForm.value = false;
      setTimeout(() => {
        showThirdForm.value = true;
      }, 400);
    }
    if (currentStep.value !== mySteps.value.length - 1) {
      currentStep.value++;
    }
  }, 400);
};

const goToPrevious = () => {
  clearTimeout(previousTimer.value);
  previousTimer.value = setTimeout(() => {
    if (currentStep.value === 1) {
      showSecondForm.value = false;
      setTimeout(() => {
        showFirstForm.value = true;
      }, 400);
    }
    if (currentStep.value === 2) {
      showThirdForm.value = false;
      setTimeout(() => {
        showSecondForm.value = true;
      }, 400);
    }
    if (currentStep.value !== 0) {
      currentStep.value--;
    }
  }, 400);
};

const showConfirmDialog = async () => {
  if (form.value.is_gifted_to_unknown) {
    confirmDialog.value = true;
  } else {
    const valid =
      formRef.value && formRef.value.errors
        ? (await formRef.value.validate()).valid
        : true;

    if (valid) {
      await checkGiftable(true);
    }
  }
};

const isLoginDialogClosed = (payload) => {
  if (payload.value) {
    showLogin.value = false;
    if (payload.showSignup) {
      signup();
    }
  }
};

const appendFormData = (formData, excludeKeys) => {
  for (const key in form.value) {
    if (
      !excludeKeys.includes(key) &&
      props.giftData &&
      form.value[key] !== props.giftData[key]
    ) {
      formData.append(key, form.value[key]);
    } else if (form.value[key] !== null) {
      formData.append(key, form.value[key]);
    }
  }
};

const appendCookieData = (formData) => {
  const ref = useCookie("ref");
  const promo = useCookie("promo");
  if (ref.value) {
    formData.append("ref", ref.value);
  }
  if (promo.value) {
    formData.append("promo_code", promo.value);
  }
};

const updateGift = async (formData) => {
  try {
    const res = await giftStore.updateEmail({
      form_data: formData,
      gift_id: props.giftData.id,
    });
    homeStore.fetchHome();
    await cartStore.getCartDetails();

    emitGiftSuccess(res);
    if (res.status_code === 200) {
      dialog.value = false;
    }
  } catch (err) {
    emitGiftError(err);
  } finally {
    btnLoading.value = false;
  }
};

const addNewGift = async (formData) => {
  // try {
  const res = await giftStore.addNewGift(formData);
  btnLoading.value = false;
  homeStore.fetchHome();
  emitGiftSuccess(res);

  if (
    (form.value.type === "new" &&
      route.name !== "cart" &&
      res.success == true) ||
    (route.name === "cart" && props.checkIsRelatedCourse && res.success == true)
  ) {
    const cartFormData = new FormData();
    appendCookieData(cartFormData);
    cartFormData.append("product_id", props.productData.id);
    cartFormData.append("gift_id", res.data.gift_id);
    cartFormData.append("product_type", props.productType);
    await cartStore.addItemToCart(cartFormData);
  } else {
    await cartStore.getCartDetails();
  }
  if (res.status_code === 200) {
    dialog.value = false;
  }
};

const emitGiftSuccess = (res) => {
  emit("onGift", {
    message: res.message,
    success: res.status_code === 200,
  });
};

const emitGiftError = (err) => {
  emit("onGift", {
    message: err.response.data ? err.response.data.message : "حدث خطأ",
    success: false,
  });
};

const resetForm = () => {
  currentStep.value = 0;
  showFirstForm.value = true;
  showSecondForm.value = false;
  showThirdForm.value = false;

  form.value = {
    is_gifted_to_unknown: false,
    gifted_to_email: "",
    type: "new",
    is_messaged: false,
    sender: "",
    recipient: "",
    message: "",
  };

  if (formRef.value && formRef.value.props) {
    formRef.value.resetValidation();
  }
};

const signup = () => {
  navigateTo("/auth/signup");
};

onMounted(() => {
  setTimeout(() => {
    showProgress.value = true;
  }, 1500);
});
</script>
<style lang="scss">
@import "./assets/scss/gift-dialog.scss";
.dialog-content {
  max-height: 90vh;
  overflow-y: auto;
}
.dialog-content::-webkit-scrollbar {
  display: none;
}
.dialog-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.gift-btn {
  height: 48px !important;
  background-color: #fff !important;
  border: solid 1px #87599d !important;
  border-radius: 47px !important;
  color: #87599d !important;
}
</style>
